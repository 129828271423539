import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/map';
import { catchError, map, tap, debounce } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AppConstants } from '@app/configs/app.constants';
const credentialsKey = 'currentUser';
@Injectable()
export class AuthenticationService {
    cookieValue = 'UNKNOWN';
    constructor(private http: HttpClient, private router: Router){    
    }

    signup(signupData: any): Observable<any> {
        return this.http.post<any>(environment.signup, signupData).pipe(
            tap(
                function (result) {
                    return result.data;
                }
            )
        );
    }

    login(loginData: any): Observable<any> {
        const body = new URLSearchParams();
        body.set('grant_type', AppConstants.GRANT_TYPE);
 
        body.set('username', loginData.userName);
        body.set('password', loginData.password);

       
      return this.http.post<any>(environment.login, body.toString(), { headers: { cors: "*" } }).pipe(
            tap(
                function (result) {
                    return result.data;
                }
            )
        );
    }

    


    logout(pageName:string=''): Observable<boolean> {
        sessionStorage.removeItem(credentialsKey);
        localStorage.removeItem(credentialsKey);
        return observableOf(true);
    }

    getUserInfo(): Observable<any> {
        const savedCredentials = this.getUser();
        return observableOf(savedCredentials);
    }

    isLogin() {
        if (localStorage.getItem(credentialsKey) || sessionStorage.getItem(credentialsKey)) {
            return true;
        }
        return false;

    }

    getToken() {
        const savedCredentials = this.getUser();
        return savedCredentials && savedCredentials['access_token'];
    }

    getAccountId() {
        const savedCredentials = this.getUser();
        return savedCredentials && savedCredentials['AccountId'];
    }

    // setToken(invalidToken){
    //     const savedCredentials = this.getUser();
    //     savedCredentials['token'] = invalidToken;
    //     sessionStorage.setItem('currentUser', JSON.stringify(savedCredentials));  
    // }

    getRefreshToken() {
        const savedCredentials = this.getUser();
        return savedCredentials && savedCredentials['refereshToken'];
    }

    getUserRole(): Observable<any> {
        const savedCredentials =  this.getUser();
        return observableOf(savedCredentials['roleName']);
    }

    getUserType() {
        const savedCredentials =  this.getUser();
        if ( this.isLogin() ) {
            return savedCredentials['roleName'];
        } else {
            return false;
        }
    }


     getUser() {
        const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
         return JSON.parse( savedCredentials );
    }

    // refreshToken(): Observable<any> {
    //     const savedCredentials =  this.getUser();
    //     let tokenObj: any = {
    //         'token': this.getToken(),
    //         'refreshToken': this.getRefreshToken()
    //     }
    //     return this.http.post<any>(environment.refreshToken, tokenObj).pipe(
    //         tap(
    //             function (result) {
    //                if (result.success === true) {
    //                     let storage = sessionStorage;
    //                     if(savedCredentials['remember']){
    //                         storage = localStorage;
    //                         result.data.remember = savedCredentials['remember'];
    //                     }

    //                     result.data.loggedInUser = true;
    //                     storage.setItem(credentialsKey, JSON.stringify(result.data));
                        
    //                 }
    //                 return result.data;
    //             }
    //         )
    //     );
    // }


}

