// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//FOR SPELLBEE site7    // FOR NOF site2
//host: 'http://nofsandesh-001-site7.ctempurl.com/',
//portalHost: 'http://nofsandesh-001-site7.ctempurl.com/api/',
  
export const environment = {
  production: false,

//Following two settings for nofexam.com
host: 'https://services.nofexam.com/',
portalHost: 'https://services.nofexam.com/api/',
 
//  host: 'http://nofsandesh-001-site14.ctempurl.com/',
//  portalHost: 'http://nofsandesh-001-site14.ctempurl.com/api/',

//  host: 'https://pretestapi.nofexam.com/',
//  portalHost: 'https://pretestapi.nofexam.com/api/',

  //host: 'http://localhost:55660/',
  //portalHost: 'http://localhost:55660/api/',

  login: 'token',
  signup: 'Mobile/NOFWeb/NOFEO/Account'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
