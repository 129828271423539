import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
  } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthenticationService } from '../authentication/authentication.service';
  
  @Injectable()
  export class SessionRecoveryInterceptor implements HttpInterceptor {
    constructor(
      private readonly authenticationService: AuthenticationService
    ) {}
  
    private _refreshSubject: Subject<any> = new Subject<any>();
  
    private _ifTokenExpired() {
      this._refreshSubject.subscribe({
        complete: () => {
          this._refreshSubject = new Subject<any>();
        }
      });
      if (this._refreshSubject.observers.length === 1) {
        // Hit refresh-token API passing the refresh token stored into the request
        // to get new access token and refresh token pair
       
        //this.authenticationService.refreshToken().subscribe(this._refreshSubject);
      }
      return this._refreshSubject;
    }
  
    private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
      return (
        error.status &&
        error.status === 401 &&
        error.statusText === 'Unauthorized'
      );
    }
  
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      if (req.url.endsWith("/logout") || req.url.endsWith("/Refresh")) {
        return next.handle(req);
      } else {
        return next.handle(req).pipe(
          catchError((error, caught) => {
            if (error instanceof HttpErrorResponse) {
              if (this._checkTokenExpiryErr(error)) {
                return this._ifTokenExpired().pipe(
                  switchMap(() => {
                    return next.handle(this.updateHeader(req));
                  })
                );
              } else {
                return throwError(error);
              }
            }
            return caught;
          })
        );
      }
    }
  
    updateHeader(req) {
      const authToken = this.authenticationService.getToken();
      req = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${authToken}`)
        
      });
      return req;
    }
  }
