import {HttpHeaders} from "@angular/common/http";
export class AppConstants {
  public static get GRANT_TYPE(): string { return "password"; };
  public static get REFRESH_GRANT_TYPE(): string { return "refresh_token"; };
  public static get DEVICEIPADDRESS():string { return "10.10.75.75"; };
  public static get CLIENT_ID(): string { return "64c7ffd32e0e426b99a5513b40217c6f"; };
  public static get AUTH_HEADERS(): any { 
    return new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
      //.set('Access-Control-Allow-Origin', '*').set("Access-Control-Allow-Headers","*")
      ;
  };
  public static get API_HEADERS(): any { 
    return new HttpHeaders().set('Content-Type', 'application/json')
      //.set('Access-Control-Allow-Origin', '*').set("Access-Control-Allow-Headers","*")
      ;
  };

  public static MESSAGES(messageKey) : any {
    const MessageArray = {
      "NOT_FOUND" : "No record(s) found.",
      "SEARCH_MSG" : "Please provide search values from the filters provided above.",
      "FEEDBACK_SUBJECT": 'feedback'
    }
    return MessageArray[messageKey];
  }
}
