<div class="container">
    <div class="row">
      <div class="col-sm-12 headingtxt">
        <h4>{{NameOfOlympiad}}</h4>
      </div>  
    </div>
    <div class="row">
      <div class="col-sm-6 bdrdivsr">
        <h3 class="text-center">NOF International Olympiads</h3>
        <!--<h3 class="text-center">SPELLBEE Global Exam Instructions</h3>-->
        <ol>
          <li>This Olympiad Exam has <b>30 MCQs</b> (Multiple Choice Questions).</li>
          <li>The student needs to click on the correct option from the multiple choices. <b>There can only be one correct answer.</b></li>
          <li>There is <b>NO NEGATIVE marking.</b></li>
          <li><b>The duration of the exam is 45 minutes.</b></li>
          <li>If due to any reason (technical, loss of internet, system crash, server problem or any other valid reason) the exam stops, a child can restart the exam. <b>ONLY TWO such attempts are allowed.</b></li>
          <li>At any point during the exam <b>do not refresh or click on the back button of the browser</b> as this will log you out of the examination.</li>
          <li><b>Use a fast reliable and continued internet connection.</b> Do not use an internet connection that frequently disconnects. A minimum of 512 kbps upload speed is preferred.</li>
          <li>Kindly ensure that you have the latest version of Google Chrome. For Mac users, do not use the Safari browser, <b>USE ONLY GOOGLE CHROME.</b></li>
          <li>This exam is powered by video proctoring hence <b>any unneeded interference by any adult during the exam will be monitored and may lead to disqualification of the exam. Hence you have to ALLOW the camera to be on.</b></li>
          <li><b>We have created a demo exam which is free for all the students</b>, this will help you understand the interface of our exam portal hence, and it has mixed questions from all the subjects of that particular class. However, your actual examination will have the entire 30 questions from the same subject. So do not get confused.</li>
          <li>To have a great user experience, prefer attempting the exam on a laptop/ desktop. However, the exam can also be attempted on a smartphone.</li>
          <li>For any queries, write us at exam@groupnof.com</li>
        </ol>
        <br><br>
        <div class="text-center">
          <button (click)="startExam()" [disabled]="isStartExamBtn" class="btn">Start Exam</button>
      </div>
      <br>
      </div>
      <div class="col-sm-6 bdrdivsr">
        <div class="row">
          <div class="col-sm-8 p1 mrright">
            <webcam [height]="350" [width]="350" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
          [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
          [videoOptions]="videoOptions"
          [imageQuality]="1"
          (cameraSwitched)="cameraWasSwitched($event)"
          (initError)="handleInitError($event)"
  ></webcam>
  
          <!-- <a *ngIf="!isStartExamBtn" class="actionBtn" href="javascript:void(0)" (click)="triggerSnapshot();" title="Take a snapshot"><i class="fa fa-2x fa-camera"></i></a> -->
          <br><br>
            <div class="text-right" *ngIf="!isStartExamBtn">
              <!-- <a href="javascript:void(0)" (click)="uploadImage()"><button class="btn">Upload Image</button></a>&nbsp; -->
            
              <!-- <a href="javascript:void(0)" (click)="removeImage()"><button class="btn">Delete Images</button></a> -->
            </div>
        </div>
          <div class="col-sm-4" >
            <div class="row snapshot" *ngIf="webcamImage">
              <div class="col-sm-6" *ngFor="let item of imageArray; let imageIndex = index">
                <ng-container  *ngIf="imageIndex<6">
                  <img [src]="item.imageAsDataUrl"/>
                </ng-container>
              </div>
  
            </div><br><br>
          
            <!-- <a href="javascript:void(0)" (click)="uploadImage()">Upload Image</a> -->
            
            <!-- //web cam -->
          </div>
  
          
        </div>
       
       
          
  
      
    </div>
    </div>
      
     
  
  </div>
  
  <style>
    a{
      color:#000;
    }
  </style>
